<template>
  <div class="index-container">
    <dataFilter ref="dataFilter" :filter="filter" :queryType="queryType">
      <el-button type="primary" @click="handleQuery()">查询</el-button>
    </dataFilter>
    <div class="tabs-container">
      <el-tabs v-model="queryType" @tab-click="typeChange">
        <el-tab-pane
          v-for="tab in tabs"
          :key="tab.key"
          :name="tab.key"
          :label="tab.name"
        >
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="tab-content">
      <dataList ref="dataList" :filter="filter" />
    </div>
  </div>
</template>
<script>
import dataList from "./components/dataList.vue";
import dataFilter from "./components/dataFilter.vue";
export default {
  components: { dataList, dataFilter },
  data() {
    return {
      tabs: [],
      filter: "",
      queryType: "all",
    };
  },
  methods: {
    handleQuery() {
      let tempfilter = this.$refs.dataFilter.getfilter();
      tempfilter.queryType = this.queryType;
      this.filter = tempfilter;
    },
    typeChange(v) {
      this.handleQuery();
    },
  },
  mounted() {
    let allTabs = [
      {
        key: "all",
        name: "全部",
        value: 0,
      },
    ];
    this.tabs = allTabs.concat(this.$config.attachmentType);
    this.handleQuery();
  },
};
</script>
<style lang="scss" scoped>
.index-container {
  .pagination {
    margin-top: 10px;
    text-align: right;
  }
  padding: 10px;
  .input {
    width: 150px;
  }
  .tabs-container {
    /deep/.el-tabs--border-card > .el-tabs__content {
      padding: 0;
      height: 1px;
    }
    .tab-content {
      margin-top: -2px;
      border: 1px solid #dcdfe6;
      border-top: 0;
      padding: 15px;
      background: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    }
  }
}
</style>